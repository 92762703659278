import { useStaticQuery, graphql } from "gatsby"

export const useAllEventData = () => {
  return useStaticQuery(
    graphql`
      query allEvents {
        allDatoCmsEvent {
          edges {
            node {
              ...EventFields
            }
          }
        }
      }
    `
  ).allDatoCmsEvent.edges.flatMap((edge) => edge.node)
}
