import React from "react"
import ScheduleItemTime from './schedule-item-time.js'
import NavigationNode from './navigation-node'
import { FiMapPin} from 'react-icons/fi';
import LocatableIcon from './locatable-icon'
import moment from "moment"
export default (props) => {
  const {locatable, tbd, locatable: {title, scheduleItem, subcategories, location, locations, universalTags, heroImage, events}} = props
  const hasSubcategories = subcategories && subcategories.length !== 0
  const hasUniversalTags = universalTags && universalTags.length !== 0



  return (
    <NavigationNode locatable={locatable} className="uk-link-reset" >
      <div className="uk-card uk-background-default uk-card-body uk-margin-small-bottom card-border-top">
        <div className="uk-flex">
          <div className="uk-flex-1">
            {(scheduleItem || tbd) && (
              <div className="card-heading uk-text-primary uk-text-uppercase">
                {tbd && "TBD"}
                {scheduleItem && (
                  <ScheduleItemTime scheduleItem={scheduleItem} events={events} />
                )}
              </div>
            )}
            <h4 className="text-saddle" style={{marginTop: "6px", marginBottom: "6px" }}>{(scheduleItem && scheduleItem.title) || title}</h4>
            {
              (location || locations ) && (
                <div className="uk-flex uk-flex-middle">
                  <span className="text-cement"><FiMapPin size=".8em" style={{marginRight: "3px", paddingBottom: "1px"}}  /></span>
                  <p className="uk-margin-remove uk-text-small text-accessible-cement">
                    {location && location.title}
                    {locations && (locations.length === 1) && locations[0].title}
                    {locations && (locations.length > 1) && "Multiple locations"}
                  </p>
                </div>
              )
            }
            {
              (hasSubcategories || hasUniversalTags) && (
                <p className="uk-text-small uk-margin-remove uk-flex uk-flex-middle">
                  <span className="text-cement"><LocatableIcon locatable={locatable} />&nbsp;</span>
                  <div style={{paddingTop: "2px"}} className="text-accessible-cement">
                    {hasSubcategories && subcategories[0].title && (
                      <span className="uk-text-uppercase">{subcategories[0].title}</span>
                    )}
                    {hasSubcategories && hasUniversalTags &&
                    <span>&nbsp;•&nbsp;</span>
                    }
                    {hasUniversalTags && universalTags.map((tag) => tag.title).join(", ")}
                  </div>
                </p>
              )
            }

          </div>
          <div className="uk-flex-none uk-margin-left figure-thumbnail">
            <img  alt={title} data-src={heroImage && `${heroImage.url}?fit=crop&w=400&h=300`} width="133" height="86" uk-img="" />
          </div>
        </div>
      </div>
    </NavigationNode>
  )
}
