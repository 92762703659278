import React from 'react'
import moment from 'moment'

export default (props) => {
  const { scheduleItem: {startDate, endDate, startTime } } = props
  const today = moment()
  let isToday = false;
  if (startDate && endDate) {
    isToday =  today.isBetween(startDate, endDate, 'day', '[]' )
  }

  return (
    <>
      {isToday ? "Today" : moment(startDate).format('MMM D')}
      {startTime && (
        <span>&nbsp;•&nbsp;</span>
      )}
      {startTime}
    </>
  )
}
