import React, { useState } from "react"
import ResultCard from "./result-card"
import {expander} from '../constants/iterators';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment'
import {useAllEventData} from '../hooks/events'

import clone from 'lodash/clone';
import {groupBy} from 'lodash';


export default ({locatables, isSchedule, visibleItems, showUnscheduledActivities}) => {
  // HACK: This hack is necessary because of a difference in the filtering/sorting of locatables
  // between the `gatsby build` and React.js rendering processes, as well as an issue with react.js
  // being able to reconcile components with the server-rendered versions. By returning `null` when
  // `window` is `undefined`, we ensure that the content is not returned until react.js has full
  // control of the rendering of the content. //
  if (typeof window === "undefined") return null;

  const [selectedDay, handleDayChange] = useState(moment())
  const [visibleItemsSize, setVisibleItemsSize] = useState(visibleItems)
  const [activeUniversalTags, onTagClick] = useState([])
  const closedDays = useAllEventData().flatMap((event) => event.days).filter((day) => !day.open)
  const closedDay = closedDays.find((day) => moment(day.date).isSame(selectedDay, 'day'))
  const stateFairClosedText = useAllEventData()[0].closedMessaging || "Sorry, the fair is closed on this day";
  const universalTagsFromLocatables = (locatables) => {
    return locatables.reduce((universalTags, locatable) => {
      locatable.universalTags.forEach( (tag) => {
        !universalTags.includes(tag.title) && universalTags.push(tag.title)
      })
      return universalTags;
    }, [])
  }

  let sections = null;
  let unscheduledLocatables = []
  if (activeUniversalTags.length) {
    locatables = locatables.filter((locatable) => {
      return activeUniversalTags.every((a) => {
        return locatable.universalTags.map((tag) => tag.title).includes(a)
      })
    })
  }
  locatables = locatables.sort((a,b) => {
    return (a.title < b.title) ? -1 : (a.title > b.title) ? 1 : 0;
  })
  if (isSchedule) {
    unscheduledLocatables = locatables.filter(locatable => !locatable.scheduleItems || locatable.scheduleItems.length === 0)
    locatables = locatables
    // TODO: handle locatables with no schedule items
      .filter(locatable => locatable.scheduleItems && locatable.scheduleItems.length !== 0)
      .map(locatable => {
        const parentEvent = locatable.events ? locatable.events[0] : {};

        locatable.expandedScheduleItems = locatable.scheduleItems
          .flatMap(scheduleItem => {
            const startDate = moment(scheduleItem.startDate || parentEvent.startDate || undefined);
            const endDate = moment(scheduleItem.endDate || parentEvent.endDate || undefined);
            const numberOfOccurances = Math.max(Math.abs(startDate.diff(endDate, 'days')) + 1, 1);
            return ((n) => {
              let returnVal = [];
              for(let i = 0; i < n; i++){
                const date = startDate.format("YYYY-MM-DD");
                startDate.add(1, 'day');
                returnVal.push(date);
              }
              return returnVal;
            })(numberOfOccurances).map(date => {
                const isClosed = closedDays.find((day) => moment(day.date).isSame(date, 'day'))
                if (!isClosed) {
                  const recurring = clone(scheduleItem);
                  recurring.startDate = date;
                  recurring.endDate = date;
                  recurring.startTime = scheduleItem.startTime || "12:00 AM"
                  recurring.endTime = scheduleItem.endTime || "11:59 PM"
                  recurring.id = scheduleItem.id + "-" + date + "-" + (scheduleItem.startTime || "allday");
                  return recurring;
                } else {
                  return false
                }

              })
          })
        return locatable;
      })
      .flatMap(expander("expandedScheduleItems", "scheduleItem"))
      .filter((locatable) => {
        const { scheduleItem: {startDate, endDate, endTime }} = locatable
        const now = moment();
        let isVisible = false;
        if(startDate && endDate){
          const isToday = now.isSame(selectedDay, "day");
          if(isToday){
            isVisible = now.isBefore(moment(endDate + " " + endTime, "YYYY-MM-DD HH:mm A"));
          } else {
            isVisible = selectedDay.isSameOrBefore(startDate, 'day');
          }
        }
        return isVisible;
      })
      .sort((a,b) => {
        if (!a.scheduleItem && b.scheduleItem) return -1;
        if (a.scheduleItem && !b.scheduleItem) return 1;
        if (!a.scheduleItem && !b.scheduleItem) return 0;
        const {startDate: aStartDate, startTime: aStartTime} = a.scheduleItem;
        const {startDate: bStartDate, startTime: bStartTime} = b.scheduleItem;
        const aStartDateTime = moment(aStartDate + " " + aStartTime, "YYYY-MM-DD h:m A");
        const bStartDateTime = moment(bStartDate + " " + bStartTime, "YYYY-MM-DD h:m A");
        return aStartDateTime - bStartDateTime;
      })
  }

  let allLocatablesLength = locatables.length

  locatables = locatables.slice(0, visibleItemsSize)
  let now = moment();
  if (isSchedule) {
    const groupedLocatables = groupBy(locatables, (i) => (i.scheduleItem.startDate));
    sections = Object.keys(groupedLocatables).map((key) => {
      return { id: key, title: now.isSame(key, 'day') ? "Today" : moment(key).format("ddd, MMM D"), locatables: groupedLocatables[key] };
    })
  }

  return (
    <div>
      <div className="uk-flex uk-flex-center@s uk-flex-wrap uk-margin-left uk-margin-remove-left@s" uk-margin="" style={{marginBottom: "12px"}}>
        {isSchedule && (
          <div className="uk-inline uk-margin-small-right">
           <span className="uk-form-icon" uk-icon="icon: calendar; ratio: .9"></span>
           <span className="uk-form-icon uk-form-icon-flip" uk-icon="icon: triangle-down"></span>
           <DayPickerInput
             placeholder="Today"
             onDayChange={(day) => handleDayChange(moment(day))}
             selectedDay={selectedDay}
             component={props => <input {...props}
               style={{paddingLeft: "35px"}}
               className="uk-input uk-form-small uk-form-width-small tag tag-primary" readOnly={true} />}  />
          </div>
        )}
        {universalTagsFromLocatables(locatables).map((tag, i) => (
          <button key={i} onClick={() => onTagClick(activeUniversalTags.includes(tag) ? activeUniversalTags.filter((t) => t !== tag) : activeUniversalTags.concat(tag))} className={`uk-button uk-button-small tag uk-margin-small-right ${activeUniversalTags.includes(tag) ? "tag-primary" : "tag-muted"}`}>{tag}</button>
        ))}
      </div>
      {
        closedDay && (
          <div>
          <h6 className="uk-text-uppercase uk-margin-left uk-margin-small">{moment(closedDay.date).format("ddd, MMM D")}</h6>
            <p style={{textAlign: "center"}}>{stateFairClosedText}</p>
          </div>
        )
      }
      { sections ? sections.map((section) => (
        <div key={section.id}>
          <h6 className="uk-text-uppercase uk-margin-left uk-margin-small">{section.title}</h6>
          {section.locatables.map((locatable, i) => (
            <ResultCard key={i} locatable={locatable} />
          ))}
        </div>

      )) : locatables.map((locatable, i) => (
        <ResultCard key={i} locatable={locatable} />
      ))}
      {
        showUnscheduledActivities && unscheduledLocatables.length !== 0 && (
          <div>
            <h6 className="uk-text-uppercase uk-margin-left uk-margin-small">Date to be determined</h6>
            {
              unscheduledLocatables.map((locatable, i) => (
                <ResultCard key={i} locatable={locatable} tbd />
              ))
            }
          </div>
        )
      }
      {
        allLocatablesLength !== 0 && allLocatablesLength >= visibleItemsSize && (
          <div className="uk-flex uk-flex-center uk-margin-top"><button onClick={() => setVisibleItemsSize(visibleItemsSize + 50)} className="uk-button uk-button-default">Show more</button></div>
        )
      }


    </div>
  )
}
