import React from "react"
import NavigationNode from './navigation-node'
import SliderNavigation from './slider-navigation'
import moment, { Moment as selectedDay } from "moment"
export default ({widget: {title, linkList, slideList }}) => {
  const hasLinkList = linkList && linkList.length !== 0
  const slidesLength = slideList && slideList.filter(sl => sl.locatable).length
  const hasSlides =  slidesLength !== 0

  function notExpired(locatable) {
    const lastScheduleItem = locatable.scheduleItems ? locatable.scheduleItems[locatable.scheduleItems.length - 1] : null;
    if(lastScheduleItem === null || !lastScheduleItem){ return false; }
    const { startDate, endDate, endTime }= lastScheduleItem;
    const now = moment();
    let isVisible = false;
    if(startDate && endDate && endTime) {
      const [year, month, day] = endDate.split("-");
      let comparisonDate = moment();
      comparisonDate.set("date", day);
      comparisonDate.set("month", month - 1);
      comparisonDate.set("year", year);
      let momentTime = moment(endTime, ['h:m a', 'H:m']);
      comparisonDate.set({
        hour:   momentTime.get('hour'),
        minute: momentTime.get('minute'),
        second: momentTime.get('second')
      });
      isVisible = now.isBefore(comparisonDate);
    }
    return isVisible;
  }

  return (
    <section className="uk-section">
      <div className="uk-container padding-remove@s">
        <div uk-grid="" className="uk-flex uk-flex-center">
          {
            hasLinkList && (
              <div className="uk-width-1-1 uk-width-1-4@m uk-text-center">
                <h3 className="uk-text-uppercase">{title}</h3>
                <ul className="uk-list">
                  {linkList.map((item, i) => (
                    <li className="uk-text-uppercase uk-text-bold" key={i}><NavigationNode locatable={item.page} node={{externalLink: item.externalLink}}>{item.label}</NavigationNode></li>
                  ))}
                </ul>
              </div>
            )
          }
          {
            hasSlides && (
              <div className={`uk-width-1-1 ${hasLinkList && "uk-width-3-4@m"}`}>
                <div className="discover-more uk-visible-toggle uk-light uk-position-relative" tabIndex="-1">
                  <div className="uk-slider-container">
                    <ul className="uk-slider-items uk-grid uk-grid-small uk-grid-match">
                      { slideList.filter(sl => sl.locatable).map(({locatable, locatable: {title, heroImage, shortDescription, showareUrl}},index) => (
                        <li className={`uk-width-3-4 uk-width-2-5@s ${hasLinkList ? "uk-width-1-3@m uk-width-1-4@l" : (slidesLength >= 6 ? "uk-width-1-4@m" : (slidesLength === 5 ? "uk-width-1-3@m" : "uk-width-1-2@m"))}`} key={index}>
                          <NavigationNode locatable={locatable} className="card-reset card-shadow">
                            <div className="uk-card uk-card-default uk-height-1-1 uk-flex uk-flex-column">
                              <div className="uk-card-media-top">
                                {heroImage && (
                                  <img alt={title} data-src={`${heroImage.url}?fit=crop&w=1000&h=650`} height="650" className="uk-animation-fade" width="1000" uk-img="target: !.uk-slider-items" />
                                )}
                              </div>
                              <div className="uk-card-body uk-light background-teal-blue uk-padding-small uk-flex-1 uk-flex uk-flex-column">
                                <div className="uk-flex-1">
                                  <h4 className="uk-margin-remove-top uk-margin-small-bottom">{title}</h4>
                                  <div className="text-tight" dangerouslySetInnerHTML={{__html: shortDescription }}></div>
                                </div>
                                {
                                  !!(showareUrl && notExpired(locatable)) && (
                                    <div className="uk-flex-none">
                                      <a className="uk-button uk-button-default uk-button-small" href={showareUrl} onClick={ (e) => { e.stopPropagation() } }>Buy Tickets</a>
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          </NavigationNode>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <SliderNavigation mobile={false} />
                </div>
              </div>
            )
          }
        </div>
      </div>
    </section>
  )
}
