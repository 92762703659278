import React from "react"
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
export default ({small, mobile}) => (
  <>
    {
      mobile && (
        <div className="uk-hidden@m uk-light">
           <button className="uk-position-center-left uk-position-small uk-icon-button slide-navigation" uk-slider-item="previous"><FaArrowLeft size="1.8em"/></button>
           <button className="uk-position-center-right uk-position-small uk-icon-button slide-navigation" uk-slider-item="next"><FaArrowRight size="1.8em"/></button>
        </div>
      )
    }
    <div className="uk-visible@m">
      <button className={`uk-position-center-left position-center-left-outside${small && "-small"} uk-position-small uk-icon-button slide-navigation icon-button-large uk-dark`} uk-slider-item="previous"><FaArrowLeft size="2.2em"/></button>
      <button className={`uk-position-center-right position-center-right-outside${small && "-small"} uk-position-small uk-icon-button slide-navigation icon-button-large`} uk-slider-item="next"><FaArrowRight size="2.2em"/></button>
    </div>
  </>
)
