import React from "react"
import moment from 'moment'

export default ({events, isThrillVille, isRide, isAlcohol}) => {
  const title = `${isAlcohol ? "Beer, wine & spirits" : (isThrillVille ? "Thrillville" : "SillyVille")} Hours`
  return (
    <>
      <h6 className="uk-text-uppercase uk-margin-left uk-margin-small-bottom">{title}</h6>
      {
        events.flatMap(ev => ev.days ).map((d) => (
          <div className="uk-margin-small-bottom">
           <div className="uk-flex uk-flex-between card-border">
             <span className="uk-text-primary uk-text-uppercase uk-text-bold">{moment(d.date).format('ddd, MMM D')}</span>
             <span className="uk-text-bold">{d.open ? (isAlcohol ? `${d.alcoholStartTime}-${d.alcoholEndTime}` : (isThrillVille ? `${d.thrillvilleStartTime}` : `${d.ridesStartTime}`)) : "Closed"}</span>
           </div>
          </div>
        ))
      }
    </>
  )
}
